.bl-database-of-numbers {
    &.without-loyalty {
        .bz-block {
            padding: 12px 16px 16px 150px;
            background: url("../../../../images/lion20.png") no-repeat;
            background-position: bottom left 0px;
            background-size: 130px;
            @media screen and (max-width: 375px) {
                padding-left: 140px;
            }
            .bz-block-content {
                margin-top: 6px;
            }
            .bz-block-title {
                font-family: "ALS Hauss";
                font-style: normal;
                font-weight: 500;
                font-size: 24px;
                line-height: 120%;
                color: #ffffff;
            }
            .text-helper {
                font-family: "ALS Hauss";
                font-style: normal;
                font-weight: 500;
                font-size: 14px !important;
                line-height: 19px;
                color: rgba(255, 255, 255, 0.5);
                white-space: pre;
                @media screen and (max-width: 992px) {
                    white-space: normal;
                }
                @media screen and (max-width: 390px) {
                    white-space: pre;
                }
            }
            .ant-btn {
                height: 34px;
                background-size: 100% 100%;
                font-family: "ALS Hauss";
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 18px;
                color: #1f1f1f;
                border-radius: 8px;
                max-width: 165px;
            }
        }
    }
    .bz-block {
        background: url("../../../../images/lion.png") no-repeat;
        background-position: bottom right -3px;
        background-size: 175px;
        border-color: #efce73;
        .text-helper {
            font-weight: 500;
        }
        .ant-btn {
            width: 100%;
        }
    }
}

@media screen and (max-width: 991px) {
    .bl-database-of-numbers {
        .bz-block {
            background: url("../../../../images/lion2.png") no-repeat;
            background-position: bottom left;
            background-size: 124px;
            padding-left: 135px;
            min-height: 146px;
            .bz-block-title {
                font-size: 24px;
                font-weight: 500;
            }
            .bz-block-content:not(:first-child) {
                margin-top: 8px;
            }
            .text-helper {
                line-height: 19px;
            }
            .ant-btn {
                height: 32px;
                max-width: 165px;
            }
        }
        .ant-space {
            gap: 11px !important;
        }
    }
}

@media screen and (max-width: 320px) {
    .bl-database-of-numbers {
        .bz-block {
            padding-left: 50px;
        }
    }
}
