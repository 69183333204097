.bl-active-sessions {
    width: 100%;
    border-radius: 8px;
    background: rgba(31, 31, 31, 0.5);
    backdrop-filter: blur(21px);
    .ant-col {
        padding-bottom: 8px;
    }
    .conteiner {
        overflow: hidden;
        border-bottom: 1px solid rgba(255, 255, 255, 0.15);

        .bl-group-sessins {
            margin-bottom: -8px;
            gap: 6px;
            flex-wrap: nowrap;
            overflow: auto;
            justify-content: flex-start;
            .ant-row {
                flex-wrap: nowrap;
            }
            .btn-complite {
                width: 150px;
                background: red;
                padding: 0 30px;
                display: flex;
                align-items: center;
                color: var(--White, #fff);
                text-align: center;
                font-family: ALS Hauss;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 140%;
                margin-bottom: 8px;
                border-radius: 1px;
            }
            .icon-devicess {
                padding: 10px;
                display: flex;
                justify-content: center;
                align-items: flex-start;
            }
            .col-content-block {
                text-align: start;
                .ant-space {
                    padding: 10px 0;
                }
            }
        }
        &:last-child {
            border-bottom: none;
        }
    }
    .title-devices,
    .sub-title-devices {
        color: var(--White, #fff);
        font-family: ALS Hauss;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
    .discription-devices {
        color: var(--white-250, rgba(255, 255, 255, 0.5));
        font-family: ALS Hauss;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        display: flex;
        align-items: center;
        svg {
            position: relative;
            top: 1px;
        }
        @media only screen and (max-width: 375px) {
            font-size: 12px !important;
        }
    }
}
