@import "../../../../styles/variables";

.bl-my-bonuses {
    &.without-loyalty {
        .ant-collapse-arrow {
            right: 0 !important;
            -webkit-transition: all 0.4s ease-in-out;
            -moz-transition: all 0.4s ease-in-out;
            -ms-transition: all 0.4s ease-in-out;
            -o-transition: all 0.4s ease-in-out;
            transition: all 0.4s ease-in-out;
        }
        .ant-collapse-item-active {
            .ant-collapse-arrow {
                transform: rotate(180deg) !important;
                top: 30%;
            }
        }
        .bz-block {
            min-height: 397px;
            .bz-block-content:not(:first-child) {
                margin-top: 30px;
            }
            .progress-row {
                justify-content: center;
                .progress-container {
                    margin-bottom: 10px;
                }
                .bl-mb-accumulated,
                .bl-mb-accrued {
                    .ant-space {
                        text-align: left;
                        gap: 0 !important;
                        .text {
                            font-family: "ALS Hauss";
                            font-style: normal;
                            font-weight: 400;
                            font-size: 16px !important;
                            line-height: 22px;
                            leading-trim: both;
                            text-edge: cap;
                            color: rgba(255, 255, 255, 0.5);
                            position: relative;
                            top: -5px;
                        }
                    }
                }
                .with-divider {
                    .ant-divider {
                        margin: 4px 0 9px;
                        border-color: #fef0b7;
                    }
                }
            }
            .bl-my-bonuses-futurePaymentSum-wrapper {
                display: flex;
                flex-direction: column;
                gap: 24px;
                margin-bottom: 0;
                .bl-my-bonuses-futurePaymentSum-wrapper-base {
                    margin-bottom: 0;
                }
                .future-container {
                    max-width: 85%;
                    width: 100%;
                    padding: 8px;
                    border-radius: 8px;
                    background: rgba(0, 0, 0, 0.3);
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    @media screen and (max-width: 576px) {
                        max-width: 100%;
                    }
                    .bl-my-bonuses-futurePaymentSum {
                        margin: 0px 20px 0 0;
                        position: relative;
                        top: 2px;
                        font-family: "ALS Alumna";
                        font-style: normal;
                        font-weight: 400;
                        font-size: 24px;
                        line-height: 100%;
                        leading-trim: both;
                        text-edge: cap;
                        color: #ffffff;
                    }
                    .bl-my-bonuses-futurePaymentSumText {
                        font-family: "ALS Hauss";
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 100%;
                        leading-trim: both;
                        text-edge: cap;
                        color: rgba(255, 255, 255, 0.5);
                    }
                }
            }
            .bl-my-bonuses-page-link-wrapper {
                margin-top: 45px;
            }
        }
    }
    .accumulated-container {
        > .ant-row {
            > .ant-col {
                padding-left: 0px !important;
                padding-right: 0px !important;
                &:first-child {
                    text-align: left;
                }
                &:last-child {
                    text-align: right;
                }
            }
        }
    }
    .bz-block .bz-block-content:not(:first-child) {
        margin-top: 50px;
    }
    &.empty {
        background: rgba(0, 0, 0, 0);
        border-radius: 8px;
    }
    .bz-block {
        min-height: 610px;
        .bz-block-title {
            text-align: center;
        }
    }
    .progress-container {
        margin-bottom: 34px;
    }
    .accumulated-container {
        margin-bottom: 24px;
    }
    .ant-btn {
        width: 100%;
    }
    .bl-my-bonuses-futurePaymentSum-wrapper {
        display: flex;
        align-items: center;
        margin-bottom: 14px;
        .bl-my-bonuses-futurePaymentSum {
            @include title-tinyFont-normal;
            line-height: 100%;
            margin-left: 20px;
            margin-right: 20px;
        }
        .bl-my-bonuses-futurePaymentSumText {
            @include tinyFont-normal;
            color: $text-pale-color;
        }
    }
    .bl-my-bonuses-futurePaymentSum-wrapper-base {
        margin-bottom: 10px;
        .ant-collapse-header {
            padding: 0px !important;
            width: 310px !important;
            .ant-row .ant-col {
                display: flex;
                align-items: center;
                &:before {
                    padding-bottom: 3px;
                    margin-right: 6px !important;
                    content: "";
                    width: 4px;
                    height: 23px;
                    background: linear-gradient(
                        141.08deg,
                        #efce73 25.79%,
                        #fef0b7 63.66%,
                        #cea86f 97.07%
                    );
                    border-radius: 8px;
                }
            }
        }
        .ant-collapse-content > .ant-collapse-content-box {
            padding-left: 7px;
        }
        .bl-my-bonuses-futurePaymentSum-wrapper-base-rateLoyalty {
            margin-left: 1px !important;
            margin-right: 42px !important;
        }
        svg {
            font-size: 21px;
            color: white !important;
            transform: rotate(90deg);
        }
        .ant-collapse-item-active {
            .ant-collapse-arrow {
                svg {
                    transform: rotate(270deg) !important;
                }
            }
        }
        .bl-my-bonuses-futurePaymentSum-wrapper-base-rateLoyalty-two-dealers {
            .ant-collapse-header {
                width: 260px !important;
            }
        }
        .ant-collapse-content,
        .ant-collapse-header-text {
            .bz-typography-regular {
                display: flex;
                p {
                    color: #fef0b7 !important;
                }
            }
        }
        .bl-my-bonuses--wrapper-ant-col {
            padding: 0px !important;
        }
    }
    .bl-my-bonuses-page-link-wrapper {
        margin-top: 54px;
    }
}

@media screen and (max-width: 1100px) {
    .bl-my-bonuses {
        .accumulated-container {
            margin-bottom: 9px;
        }
    }
}
@media screen and (max-width: 768px) {
    .bl-my-bonuses {
        .bz-block .bz-block-content:not(:first-child) {
            margin-top: 25px;
        }
        .progress-container {
            margin-bottom: 16px;
        }
        .accumulated-container {
            margin-bottom: 9px;
        }
        .bz-block {
            min-height: 544px;
        }
    }
}
@media screen and (max-width: 370px) {
    .bl-my-bonuses {
        .bl-my-bonuses-page-link-wrapper {
            margin-top: 64px;
        }
    }
}
@media screen and (max-width: 320px) {
    .bl-my-bonuses {
        .progress-container {
            .ant-col:last-child {
                padding: 0 5px 0 0 !important;
            }
        }
    }
}
