.bl-delete-all-modal {
    .ant-modal-content {
        background: transparent;
        border: 1px solid hsla(0, 0%, 100%, 0.5);
        text-align: center;
        .ant-modal-close {
            .ant-modal-close-icon {
                svg {
                    fill: #fff;
                }
            }
        }
        .ant-modal-body {
            .bz-block-default {
                border: none;
                .bz-block-content {
                    .title {
                        font-size: 32px;
                        font-family: "ALS Alumna";
                    }
                    .sub-title {
                        white-space: pre-line;
                        font-size: 12px;
                    }
                }
                .ant-btn-text {
                    font-size: 20px;
                    font-weight: 500;
                    background: transparent;
                    color: #fff;
                }
            }
        }
    }
}
