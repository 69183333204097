@import "../../../../styles/variables";

.bl-replenish-number-bonuses {
    &.without-loyalty {
        .ant-btn {
            height: 80px;
        }
    }
    cursor: pointer;
    &:hover {
        .ant-btn {
            border: 1px solid $border-hover-color;
            svg {
                path {
                    stroke: $text-hover-color;
                }
            }
        }
        .bz-typography {
            color: $text-hover-color;
        }
    }
    .ant-btn {
        width: 100%;
        background: transparent;
        padding: 0 24px;
        height: 99px;
        border: 1px solid hsla(0, 0%, 100%, 0.5);
    }
    .ant-btn:disabled {
        border: 1px solid hsla(0deg, 0%, 100%, 0.2);
        .block-title,
        .text {
            color: rgba(255, 255, 255, 0.2);
        }
        svg {
            path {
                stroke: rgba(255, 255, 255, 0.2);
            }
        }
        &:hover {
            background: transparent;
        }
    }
    .bz-block {
        padding: 0;
        .bz-block-content {
            margin-top: 0;
            .text {
                line-height: 19px;
            }
            .ant-row {
                .ant-col {
                    .block-title {
                        font-weight: 700;
                        font-size: 17px;
                        line-height: 120%;
                    }
                }
            }
        }
    }
    .icon-vector {
        svg {
            font-size: 20px;
        }
    }
    .text {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        text-align: start;
    }
    .block-title {
        text-align: start;
    }
    .ant-space-horizontal {
        width: 100%;
        justify-content: space-between;
    }
}

@media only screen and (max-width: 1124px) {
    .bl-replenish-number-bonuses {
        .ant-btn {
            padding: 0 16px;
        }
        .ant-space-horizontal {
            gap: 20px !important;
        }
    }
}

@media only screen and (max-width: 780px) {
    .bl-replenish-number-bonuses {
        .ant-space-horizontal {
            gap: 60px !important;
        }
        .text {
            font-weight: 400;
        }
    }
}
@media only screen and (max-width: 370px) {
    .bl-replenish-number-bonuses {
        .ant-space-horizontal {
            gap: 40px !important;
        }
    }
}
@media only screen and (max-width: 350px) {
    .bl-replenish-number-bonuses {
        .ant-space-horizontal {
            gap: 20px !important;
        }
    }
}
@media only screen and (max-width: 330px) {
    .bl-replenish-number-bonuses {
        .ant-space-horizontal {
            gap: 10px !important;
        }
    }
}
