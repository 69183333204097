.page-news {
    .bl-content {
        .bl-news-discription {
            padding: 0 149px;
        }
    }
    .bl-news {
        .ant-space {
            width: 100%;
        }
        .title {
            text-align: center;
        }
        .list-news-block {
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: center;
            margin: 58px 0 24px 0;
            .ant-col {
                height: 100%;
            }
        }
        .list-news-block {
            .ant-space-item {
                &:first-child {
                    .bz-block-default {
                        border: 1px solid #fcecaf;
                    }
                }
            }
        }

        .bl-list-news {
            height: 100%;
            margin: 0;
            position: relative;
            .bz-block-content {
                height: 100%;
                position: relative;
                .spase-content-news {
                    height: 100%;
                    padding-bottom: 88px;
                }
            }
            .ant-btn {
                width: 100%;
                border: 1px solid white;
                background: transparent;
                bottom: 0;
                left: 0;
                margin: auto;
                position: absolute;
                right: 0;
            }
            .published-date {
                text-align: end;
            }
            .preview-text {
                line-height: 19px;
            }
        }
        .btn-show-more {
            width: 252px;
        }
        .btn-show {
            text-align: center;
        }
        .bl-back {
            z-index: 99;
            margin-top: 0;
        }
    }
    .bl-list-news {
        width: 372px;
        height: 378px !important;
        &__red-circle {
            position: absolute;
            top: 1px;
            right: 0px;
            background: red;
            border-radius: 50%;
            width: 10px;
            height: 10px;
        }
    }
    .bl-title {
        text-align: center;
    }
}
.bl-list-news-page-wrapper-images {
    top: -25px;
    left: -16px;
    z-index: -1;
    position: absolute;
    width: 372px;
    height: 378px !important;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    -o-filter: brightness(50%);
    -ms-filter: brightness(50%);
    -webkit-filter: brightness(50%);
    filter: brightness(50%);
}

.bl-list-news-page-fallback-images {
    top: -25px;
    left: -16px;
    z-index: -1;
    position: absolute;
    width: 372px;
    height: 378px !important;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-color: rgb(0, 0, 0);
    background-image: url("../../../../images/new_preview.jpg");
    background-image: -webkit-image-set(
        url("../../../../images/new_preview@1x.webp") 1x,
        url("../../../../images/new_preview.jpg") 1x,
        url("../../../../images/new_preview@2x.webp") 2x,
        url("../../../../images/new_preview.jpg") 2x
    );
    background-image: image-set(
        url("../../../../images/new_preview@1x.webp") 1x,
        url("../../../../images/new_preview.jpg") 1x,
        url("../../../../images/new_preview@2x.webp") 2x,
        url("../../../../images/new_preview.jpg") 2x
    );
}

.preview-image-bl-list-news-page-wrapper-images {
    left: 0px;
    top: 0px;
    height: 402 !important;
}
@media all and (max-width: 1180px) {
    .preview-image-bl-list-news-page-wrapper-images {
        width: 100% !important;
    }
}
@media all and (max-width: 768px) {
    .page-news {
        .bl-content {
            .bl-news-discription {
                padding: 0 16px;
                .ui-back {
                    .ant-btn {
                        padding-left: 0;
                        svg {
                            top: -10px;
                        }
                    }
                }
            }
        }
        .bl-news {
            .bl-list-news {
                padding-top: 16px;
                width: 330px;
                height: 330px !important;
            }
            .ui-back {
                .ant-btn {
                    svg {
                        top: -5px;
                    }
                }
            }
        }
    }
    .bl-list-news-page-wrapper-images {
        top: -17px;
        width: 330px;
        height: 330px !important;
    }
    .preview-image-bl-list-news-page-wrapper-images {
        top: 0px;
        width: 100% !important;
        -webkit-transition: opacity 0.5s !important;
        transition: opacity 0.5s !important;
        visibility: visible !important;
    }
}
