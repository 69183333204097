.sidebar-profile-mobile {
    .list-sessions {
        .col-back {
            svg {
                position: absolute;
                width: 28px;
                top: 18px;
                left: 0;
            }
            .ant-btn {
                background: transparent;
                font-size: 17px;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                font-family: "ALS Alumna";
            }
        }
        .block-basic {
            .title {
                font-size: 18px;
                font-weight: 500;
                margin-bottom: 16px;
            }
        }
        .block-active-sessions {
            .title {
                font-size: 18px;
                font-weight: 500;
                margin-bottom: 8px;
            }
            .bl-scroller-wrapper {
                margin: auto;
            }
        }
        .ant-space {
            width: 100%;
        }
        .btn-close-group {
            .ant-btn {
                width: 100%;
            }
        }
    }
    .sidebar-profile-shimmer {
        width: 100%;
        .list-sessions {
            .row-group-btn-close {
                .col-back {
                    .btn-skeleton-back {
                        width: 250px;
                        border-radius: 8px;
                    }
                }
                .col-close {
                    .btn-skeleton-close {
                        width: 45px;
                        min-width: 45px;
                        border-radius: 8px;
                    }
                }
            }
            .block-basic,
            .block-active-sessions {
                .title {
                    width: 100%;
                    margin-bottom: 8px;
                    .btn-skeleton-title {
                        width: 250px;
                        border-radius: 8px;
                    }
                }
                .btn-skeleton-active {
                    width: 436px;
                    height: 105px;
                    border-radius: 8px;
                    @media only screen and (max-width: 425px) {
                        width: 100%;
                        margin-bottom: 4px;
                    }
                }
            }
            .btn-close-group {
                .btn-skeleton {
                    width: 343px;
                    height: 48px;
                    border-radius: 8px;
                    margin-bottom: 8px;
                }
            }
        }
    }
}
